import styled from 'styled-components';

import ArrowLeft from 'vectors/arrowLeft.svg';

export const SwiperNavigation = styled.div`
  display: flex;
  margin-top: 16px;
  width: 60px;
  justify-content: space-between;
`;

export const SwiperNavigationLeft = styled(ArrowLeft)`
  cursor: pointer;
`;

export const SwiperNavigationRight = styled(SwiperNavigationLeft)`
  transform: rotate(180deg);
`;
