export const menuListVariant = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      type: 'tween',
      staggerChildren: 0.05,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
};

export const menuLabelVariant = {
  visible: {
    opacity: 1,
    x: '0px',
  },
  hidden: {
    opacity: 0,
    x: '20px',
  },
};
