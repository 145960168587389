import * as React from 'react';

export const Image: React.FC<ImageProps> = ({ src, alt, fullHeight, ...props }) => {
  return (
    <img
      src={src}
      alt={alt}
      style={{
        height: `${fullHeight ? '100vh' : '90vh'}`,
        objectFit: 'cover',
        width: '100%',
      }}
      {...props}
    />
  );
};

type ImageProps = {
  src: string;
  alt: string;
  fullHeight?: boolean;
};
