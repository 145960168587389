import styled from 'styled-components';

export const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
`;

export const AccordionText = styled.div<AccordionTextProps>`
  max-height: ${({ isActive }) => isActive ? '200px' : 0};
  transition: max-height .3s ease;
  overflow: hidden;
  margin-bottom: 16px;
  margin-left: 32px;
  color: ${({ theme }) => theme.colors.gray.dark};
`;

type AccordionTextProps = {
  isActive: boolean;
};

export const Minus = styled.div`
  width: 11px;
  height: 2px;
  background: ${({ theme }) => theme.colors.black};
  margin: -3px 6px 0 7px;
`;

export const AccordionIcon = styled.div`
  margin-right: 10px;
  margin-top: 2px;

  svg {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.colors.black};
  }
`;
