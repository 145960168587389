import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next';

import { ButtonProps } from './types';
import { StyledButton, StyledButtonLoader, ButtonIcon } from './styled';

const ButtonLoader = () => (
  <StyledButtonLoader>
    <div />
    <div />
    <div />
  </StyledButtonLoader>
);

const ButtonContent: React.FC<ButtonProps> = ({ children, icon, isLoading }) => (
  <>
    {isLoading ? (
      <ButtonLoader />
    ) : (
      <>
        {icon && (
          <ButtonIcon>
            {icon}
          </ButtonIcon>
        )}
        <span>{children}</span>
      </>
    )}
  </>
);

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  children, href, icon, $iconPosition = 'left', size = 'auto', isLoading,
  onClick, to, urlTarget, $isValid, disabled, variant, $iconOnlyOnMobile, ...otherProps
}, ref) => {
  const styledButtonProps = {
    disabled,
    $isValid,
    $iconOnly: !children && icon,
    $iconOnlyOnMobile,
    $iconPosition,
    variant,
    size,
    ref,
  };

  const buttonContentProps = {
    children,
    icon,
    isLoading,
  };

  // All the components that use "to" send: /${to} in the "to" prop. This is why also external
  // urls have "/" infront of them. So we have to check on the extra "/"
  const isExternalUrl = to?.startsWith('/http');

  if (isExternalUrl && to) {
    href = to.substring(1);
    urlTarget = 'blank';
  }

  if (href) {
    return (
      <StyledButton
        as="a"
        href={href}
        target={`_${urlTarget || 'self'}`}
        {...styledButtonProps}
        {...otherProps}
      >
        <ButtonContent {...buttonContentProps} />
      </StyledButton>
    );
  }

  if (to) {
    return (
      <StyledButton
        as={GatsbyLink}
        to={to}
        {...styledButtonProps}
        {...otherProps}
      >
        <ButtonContent {...buttonContentProps} />
      </StyledButton>
    );
  }

  return (
    <StyledButton
      onClick={onClick}
      {...styledButtonProps}
      {...otherProps}
    >
      <ButtonContent {...buttonContentProps} />
    </StyledButton>
  );
});
