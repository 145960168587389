export const Headline1Variant = {
  key: 'headingFadeIn',
  initial: 'initial',
  animate: 'animate',
  variants: {
    initial: {
      y: '50%',
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.1,
        duration: 0.6,
        ease: 'easeOut',
      },
    },
  },
};
