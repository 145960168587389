import styled from 'styled-components';

import { Text } from 'common/typography';

export const TagContainer = styled.div`
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.orange};
  width: fit-content;
  border-radius: 0px 0px 4px 4px;
  margin: 0 24px 0 0;
`;

export const ExtendedText = styled(Text)`
  text-transform: uppercase;
  line-height: 20px;
  size: 12px;
  letter-spacing: 1px;
`;
