import styled from 'styled-components';

import LogoSvg from 'vectors/logo-svg.svg';

export const Logo = styled(LogoSvg)`
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 45%;
  transform: translate(-50%, 0);
  height: 75px;
  width: 100%;
`;
