import * as React from 'react';

import Add from 'vectors/add.svg';
import { Text } from 'common/typography';

import { AccordionText, AccordionTitle, AccordionIcon, Minus } from './styled';

export const Accordion: React.FC<AccordionProps> = ({ items }) => {
  const [activeItem, setActiveItem] = React.useState('');

  return (
    <>
      {items.map((item) => (
        <div key={item.title}>
          <AccordionTitle
            onClick={() => setActiveItem(activeItem === item.title ? '' : item.title)}
          >
            <AccordionIcon>
              {item.text && activeItem !== item.title ? <Add /> : <Minus />}
            </AccordionIcon>
            <Text
              $uppercase
              $size={16}
              $weight={activeItem === item.title ? 700 : 400}
              $lineHeight="24"
            >
              {item.title}
            </Text>
          </AccordionTitle>
          {item?.text && (
            <AccordionText isActive={activeItem === item.title}>
              {item.text}
            </AccordionText>
          )}
        </div>
      ))}
    </>
  );
};

export type AccordionItemsType = {
  title: string;
  text: string;
}[];

export type AccordionProps = {
  items: AccordionItemsType;
};
