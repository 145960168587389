import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

import Add from 'vectors/add.svg';
import Instagram from 'vectors/instagram.svg';
import Tiktok from 'vectors/tiktok.svg';
import Facebook from 'vectors/facebook.svg';
import Spotify from 'vectors/spotify.svg';
import { FooterCTA } from 'modules/FooterCTA';
import { Text } from 'common/typography';

import {
  FooterContainer, FooterGrid, FooterLink, SocialLinks, SocialLink, ContentContainer, FooterItems,
  FooterTitleContainer, NavigationIcon, FooterGroup, Minus, ExternalFooterLink,
} from './styled';

export const Footer = () => {
  const data = useStaticQuery<GatsbyTypes.FooterNavigationQuery>(graphql`
    query FooterNavigation {
      contentfulWebsiteDiscountcode {
        discountcode
      }
      allContentfulComponentFooterNavigation {
        edges {
          node {
            node_locale
            list {
              id
              heading
              list {
                ... on ContentfulComponentSingleNavigationItem {
                  id
                  slug
                  title
                  openInNewTab
                }
                ... on ContentfulPageGeneral {
                  id
                  slug
                  title
                }
              }
            }
          }
        }
      }
    }
  `);

  const [openFooter, setOpenFooter] = React.useState('');
  const { language } = useI18next();
  const list = data?.allContentfulComponentFooterNavigation?.edges.find((edge) => edge.node.node_locale === language);
  const items = list.node.list as FooterItemsType;
  const discountCode = data?.contentfulWebsiteDiscountcode?.discountcode;

  return (
    <FooterContainer>
      <ContentContainer>
        <FooterGrid>
          {items.map((group) => (
            <FooterGroup key={group.id}>
              <FooterTitleContainer onClick={() => setOpenFooter(openFooter === group.id ? '' : group.id)}>
                <Text
                  $uppercase
                  $size={16}
                  $margin="0 0 24px"
                  $weight={700}
                >
                  {group.heading}
                </Text>
                <NavigationIcon>
                  {openFooter !== group.id ? <Add /> : <Minus />}
                </NavigationIcon>
              </FooterTitleContainer>
              <FooterItems isOpen={openFooter === group.id}>
                {group.list.map((listItem) => (
                  <React.Fragment key={listItem.slug}>
                    {listItem.openInNewTab ? (
                      <ExternalFooterLink href={listItem.slug}>
                        {listItem.title}
                      </ExternalFooterLink>
                    ) : (
                      <FooterLink to={`/${listItem.slug}`}>
                        {listItem.title}
                      </FooterLink>
                    )}
                  </React.Fragment>
                ))}
              </FooterItems>
            </FooterGroup>
          ))}
          <FooterCTA discountcode={discountCode || ''} />
        </FooterGrid>
        <SocialLinks>
          <SocialLink href="https://www.instagram.com/saintsandstars/">
            <Instagram />
          </SocialLink>
          <SocialLink href="https://open.spotify.com/user/saints-stars">
            <Spotify />
          </SocialLink>
          <SocialLink href="https://www.tiktok.com/@saintsandstars">
            <Tiktok />
          </SocialLink>
          <SocialLink href="https://www.facebook.com/saintsandstars/">
            <Facebook />
          </SocialLink>
        </SocialLinks>
      </ContentContainer>
    </FooterContainer>
  );
};

type FooterItemsType = {
  list: { slug: string; title: string; openInNewTab?: boolean; }[];
  heading: string;
  id: string;
}[];
