import * as React from 'react';

export const EyeOpen: React.FC<EyeOpenProps> = ({ ...props }) => (
  <svg
    viewBox="0 0 24 16"
    width="24px"
    height="24px"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15c4.956 0 9.23-4.776 10.567-6.438a.887.887 0 000-1.124C21.23 5.776 16.956 1 12 1S2.77 5.776 1.433 7.438a.887.887 0 000 1.124C2.771 10.224 7.044 15 12 15zm0-3a4 4 0 100-8 4 4 0 000 8z"
    />
    <path d="M22.567 8.562l.779.627-.78-.627zm0-1.124l-.78.627.78-.627zm-21.134 0L.654 6.81l.78.627zm0 1.124l.78-.627-.78.627zm20.355-.627c-.642.798-1.986 2.339-3.75 3.672C16.26 12.95 14.165 14 12 14v2c2.791 0 5.31-1.338 7.243-2.798 1.946-1.47 3.408-3.149 4.103-4.013l-1.558-1.254zm0 .13a.107.107 0 01-.02-.065c0-.03.01-.054.02-.065l1.558 1.254a1.887 1.887 0 000-2.378l-1.558 1.254zM12 2c2.165 0 4.26 1.05 6.038 2.393 1.764 1.333 3.108 2.874 3.75 3.672l1.558-1.254c-.695-.864-2.157-2.543-4.103-4.013C17.311 1.338 14.791 0 12 0v2zM2.212 8.065c.642-.798 1.986-2.339 3.75-3.672C7.74 3.05 9.837 2 12 2V0C9.209 0 6.69 1.338 4.757 2.798 2.81 4.268 1.349 5.947.654 6.81l1.558 1.254zm0-.13c.01.011.02.035.02.065s-.01.054-.02.065L.654 6.81a1.887 1.887 0 000 2.378l1.558-1.254zM12 14c-2.164 0-4.26-1.05-6.037-2.393-1.765-1.333-3.109-2.874-3.75-3.672L.653 9.19c.695.864 2.157 2.543 4.103 4.013C6.689 14.662 9.209 16 12 16v-2zm3-6a3 3 0 01-3 3v2a5 5 0 005-5h-2zm-3-3a3 3 0 013 3h2a5 5 0 00-5-5v2zM9 8a3 3 0 013-3V3a5 5 0 00-5 5h2zm3 3a3 3 0 01-3-3H7a5 5 0 005 5v-2z" />
  </svg>
);

type EyeOpenProps = {
  onClick?: () => void;
}
