import styled, { css } from 'styled-components';
import { Link } from 'gatsby-plugin-react-i18next';

import { media } from 'styles/utils';

export const FooterContainer = styled.div`
  background: ${({ theme }) => theme.colors.gray.light};
  height: 100%;
  padding: 44px 24px;

  ${media.desktop`
    padding: 92px 156px;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.desktop`
    max-width: 1680px;
    margin: 0 auto;
  `}
`;

export const FooterGrid = styled.div`
  ${media.desktop`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  `}
`;

export const FooterGroup = styled.div`
  position: relative;
`;

export const FooterTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FooterItems = styled.div<FooterItemsProps>`
  max-height: ${({ isOpen }) => isOpen ? '300px' : '0'};
  overflow: hidden;
  transition: max-height .3s ease;
  margin-bottom: 16px;

  ${media.desktop`
    max-height: 100%;
  `}
`;

type FooterItemsProps = {
  isOpen: boolean;
};

const footerLinkStyling = css`
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 16px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const FooterLink = styled(Link)`
  ${footerLinkStyling};
`;

export const ExternalFooterLink = styled.a.attrs({
  target: '_blank',
})`
  ${footerLinkStyling};
`;

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  margin: 48px;
  gap: 24px;

  ${media.desktop`
    justify-content: flex-end;
    margin: 48px 0 0 0;
  `}
`;

export const SocialLink = styled.a.attrs({
  target: '_blank',
})`

  svg {
    fill: ${({ theme }) => theme.colors.primary};
    width: 20px;
    height: 20px;
  }
`;

export const NavigationIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  svg {
    fill: ${({ theme }) => theme.colors.black};
  }

  ${media.desktop`
    display: none;
  `}
`;

export const Minus = styled.div`
  width: 11px;
  height: 2px;
  background: ${({ theme }) => theme.colors.black};
  position: absolute;
  right: 6px;
  top: 11px;
`;
