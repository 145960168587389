import * as React from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { lockBodyScroll } from 'services';
import {
  useAuthenticationUser, useDevice, useSelector, useDispatch,
} from 'services/hooks';
import CloseIcon from 'vectors/Close.svg';
import ProfileIcon from 'vectors/profile.svg';
import ArrowIcon from 'vectors/arrowLeft.svg';
import { getMeSeries, getMeContracts } from 'ducks/meSeries';
import { getActiveRewards } from 'ducks/rewards';
import { getUserClasses } from 'ducks/me';
import { Text } from 'common/typography';
import {
  AccountNavigationOverview, AccountNavigationSeries, AccountNavigationMembercard,
} from 'modules/AccountNavigationDetails';

import {
  NavigationContainer, Overlay, NavigationHeader, IconContainer,
  AccountButton, AccountButtonText,
} from './styled';
import { desktopVariant, mobileVariant } from './animationVariants';

export const AccountNavigation: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { navigate } = useI18next();
  const user = useSelector((state) => state.me.data);
  const [isNavigationOpen, setIsNavigationOpen] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(0);
  const overlayRef = React.createRef<HTMLDivElement>();
  const { isDesktop, isMobile } = useDevice();
  const { authenticated, determined } = useAuthenticationUser();

  React.useEffect(() => {
    lockBodyScroll(isNavigationOpen);

    return () => {
      lockBodyScroll(false);
    };
  }, [isNavigationOpen]);

  React.useEffect(() => {
    if (determined && authenticated) {
      dispatch(getUserClasses());
      dispatch(getMeSeries());
      dispatch(getMeContracts());
      dispatch(getActiveRewards());
    }
  }, [determined]);

  const onClose = () => setIsNavigationOpen(false);

  const animationVariant = isDesktop ? desktopVariant : mobileVariant;

  return (
    <>
      {determined && (
        authenticated ? (
          <AccountButton onClick={() => setIsNavigationOpen(true)}>
            <ProfileIcon />
            <Text
              variant="primary"
              $margin="0 0 0 8px"
              $size={16}
            >
              {user?.firstName}
            </Text>
          </AccountButton>
        ) : (
          <AccountButton onClick={() => isMobile && navigate('/login')}>
            <ProfileIcon />
            <AccountButtonText
              onClick={() => navigate('/login')}
              loginText
            >
              {t('login')}
            </AccountButtonText>
            <AccountButtonText onClick={() => navigate('/register')}>
              {t('start today')}
            </AccountButtonText>
          </AccountButton>
        )
      )}
      {authenticated && (
        <>
          <NavigationContainer
            animate={isNavigationOpen ? 'visible' : 'hidden'}
            initial="hidden"
            variants={animationVariant}
            key="navigationContainer"
          >
            <NavigationHeader>
              <IconContainer
                animate={isNavigationOpen ? 'open' : 'close'}
                initial="open"
                variants={animationVariant}
                key="iconContainer"
              >
                <CloseIcon onClick={onClose} />
              </IconContainer>
              <IconContainer key="SecondIconContainer">
                {currentStep !== 0 && (
                  <ArrowIcon onClick={() => setCurrentStep(0)} />
                )}
              </IconContainer>
            </NavigationHeader>
            {currentStep === 0 && (
              <AccountNavigationOverview
                name={user?.firstName}
                setCurrentStep={setCurrentStep}
              />
            )}
            {currentStep === 1 && (
              <AccountNavigationSeries />
            )}
            {user && currentStep === 2 && (
              <AccountNavigationMembercard
                name={`${user.firstName} ${user.lastName}`}
                emailaddress={user.username}
              />
            )}
          </NavigationContainer>
          {isNavigationOpen && (
            <Overlay ref={overlayRef} onClick={onClose} />
          )}
        </>
      )}
    </>
  );
};
