import * as i from 'types';
import { createSelector } from 'reselect';

export const selectBookings = (state: i.ReduxState) => state.booking.data;
export const selectRewardsData = (state: i.ReduxState) => state.rewards.data;
export const selectActiveRewardsData = (state: i.ReduxState) => state.rewards.active;

export const selectLoyaltyReward = createSelector(
  selectActiveRewardsData,
  (activeRewards) => activeRewards.find((reward) => reward.name === 'LOYALTY_COUNTER'),
);

export const selectClassCounter = createSelector(
  selectActiveRewardsData,
  (activeRewards) => activeRewards.find((reward) => reward.name === 'TOTAL_CLASSES'),
);
