import * as React from 'react';

import CloseIcon from 'vectors/Close.svg';
import { Modal } from 'common/layout';

import { VideoContainer, Video, CloseButton } from './styled';

export const VideoModal: React.FC<VideoModalProps> = ({ isOpen, onClose, videoUrl }) => {
  return (
    <Modal {...{ isOpen, onClose }}>
      <VideoContainer>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <Video
          autoPlay
          playsInline
          controls
        >
          <source src={videoUrl} type="video/mp4" />
        </Video>
      </VideoContainer>
    </Modal>
  );
};

type VideoModalProps = {
  isOpen: boolean;
  onClose: () => void;
  videoUrl: string;
};
