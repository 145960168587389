import styled from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';

export const CreditsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CreditsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  height: 48px;
  padding: 4px 8px;
  min-width: 160px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
  cursor: pointer;
`;

export const CreditsSvgWrapper = styled.div`
  width: 40px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray.light};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary};
`;

export const NavigationFooter = styled.div`
  padding: 112px 32px 0 32px;

  ${media.desktop`
    padding: 112px 96px 0 96px;
  `}
`;

export const NavigationItem = styled(motion.div)`
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.primary};
  & > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};
  }
  cursor: pointer;
`;

export const NavigationList = styled(motion.div)`
  padding: 0 32px 0 32px;

  ${media.desktop`
    padding: 0 96px 0 96px;
  `}
`;

export const UserDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 32px 0 32px;

  ${media.desktop`
    padding: 0 96px 0 96px;
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
