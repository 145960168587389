import * as i from 'types';
import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { LogoIcon } from './styled';

export const Logo = ({ variant }: LogoProps) => {
  const { navigate } = useI18next();

  return (
    <LogoIcon variant={variant} onClick={() => navigate('/')} />
  );
};

type LogoProps = {
  variant?: i.ThemeColors;
};
