import styled from 'styled-components';

import { media } from 'styles/utils';
import { Text } from 'common/typography';

export const Span = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 21px;
  align-items: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
  margin-left: 9px;
`;

export const ClassesOfText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 14px;
  display: none;

  ${media.desktop`
    display: block;
  `}
`;
