import * as React from 'react';

import MenuIcon from 'vectors/menuIcon.svg';

import { StyledButton } from './styled';

export const NavigationButton: React.FC<NavigationButtonProps> = ({ openNavigation }) => {
  return (
    <StyledButton onClick={openNavigation}>
      <MenuIcon />
    </StyledButton>
  );
};

type NavigationButtonProps = {
  openNavigation: () => void;
};

