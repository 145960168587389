import * as React from 'react';

import { SwiperNavigation, SwiperNavigationLeft, SwiperNavigationRight } from './styled';

export const CarouselNavigation = () => {
  return (
    <SwiperNavigation>
      <SwiperNavigationLeft className="carousel__prev" />
      <SwiperNavigationRight className="carousel__next" />
    </SwiperNavigation>
  );
};
