import theme from 'styles/theme';

export default {
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: state.menuIsOpen ? '24px 24px 0px 0px' : '24px',
    padding: '8px 0px 5px',
    transition: 'none!important',
    border: '0',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1);',
    backgroundColor: `${theme.colors.white}`,
    cursor: 'pointer',
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    paddingRight: '8px',
    '& svg': {
      transform: state?.selectProps.menuIsOpen
        ? 'rotate(180deg)'
        : 'rotate(0)',
    },
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: `${theme.colors.black}`,
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: `${theme.colors.white}`,
    marginTop: '-1px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1);',
    borderTop: `1px solid ${theme.colors.gray.light}`,
    borderRadius: '0px 0px 24px 24px',
  }),
  menuList: (provided: any) => ({
    padding: '0',
  }),
  option: (provided: any, state: any) => ({
    backgroundColor: `${theme.colors.white}`,
    padding: '12px 0px',
    height: '44px',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '14px',
    transition: 'all 200ms',
    '&:hover': {
      backgroundColor: `${theme.colors.gray.light}`,
      cursor: 'pointer',
    },
    '&:last-child': {
      borderRadius: '0px 0px 24px 24px',
    },
  }),
  singleValue: (provided: any, state: any) => ({
    textTransform: 'uppercase',
    color: `${theme.colors.black}`,
    fontSize: '14px',
    lineHeight: '14px',
  }),
};
