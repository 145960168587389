export const mobileVariant = {
  hidden: {
    x: '-100%',
    transition: {
      type: 'tween',
    },
  },
  visible: {
    x: '0',
    transition: {
      type: 'tween',
    },
  },
};

export const closeButtonVariant = {
  open: {
    opacity: 1,
    delay: 0.4,
    transition: {
      duration: 0.3,
      ease: 'easeOut',
      transition: 'tween',
    },
  },
  closed: {
    opacity: 0,
    transition: {
      ease: 'easeOut',
      transition: 'tween',
    },
  },
};

export const desktopVariant = {
  hidden: {
    x: '-600px',
    transition: {
      ease: 'easeOut',
      type: 'tween',
      duration: 0.3,
    },
  },
  visible: {
    x: '0',
    transition: {
      ease: 'easeIn',
      type: 'tween',
      duration: 0.3,
    },
  },
};

export const menuListVariant = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      type: 'tween',
      staggerChildren: 0.05,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
};

export const menuLabelVariant = {
  visible: {
    opacity: 1,
    x: '0px',
  },
  hidden: {
    opacity: 0,
    x: '20px',
  },
};
