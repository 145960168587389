import styled from 'styled-components';
import LogoSvg from 'vectors/logoVariant.svg';

import { Text } from 'common/typography';
import { media } from 'styles/utils';

export const NavigationContainer = styled.div`
  padding: 0 32px 0 32px;

  ${media.desktop`
    padding: 0 96px 0 96px;
  `}
`;

export const Subtitle = styled(Text)`
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 18px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HorizontalLine = styled.div`
  border-radius: 4px;
  width: 100%;
  opacity: 0.5;
  border: 1px solid ${({ theme }) => theme.colors.gray.background};
`;

export const MembershipCardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px 0px 24px;
  gap: 16px;
`;

export const StyledLogo = styled(LogoSvg)`
  height: 130px;
  width: 118px;
`;

export const MembershipCardWrapper = styled.div`
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  height: 460px;
  width: 294px;
  display: flex;
  flex-direction: column;
  margin-top: 25px;

  ${media.desktop`
    height: 510px;
    width: 344px;
  `}
`;

export const MembershipCardImage = styled.div<CardImageProps>`
  height: 252px;
  width: 296px;
  background-image: ${({ background }) => `url(${background})`};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px 16px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.desktop`
    height: 302px;
    width: 344px;
  `}
`;

type CardImageProps = {
  background?: string;
};
