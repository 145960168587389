import * as i from 'types';
import styled, { css } from 'styled-components';

import { getColor } from 'styles/utils';

export const Text = styled.p<i.TextDefaultProps>`
  line-height: ${({ $lineHeight }) => $lineHeight || 16}px;
  font-size: ${({ $size }) => $size || 14}px;
  font-weight: ${({ $weight }) => $weight || 400};
  margin: ${({ $margin }) => $margin || 0};
  letter-spacing: 0.01em;
  font-family: ${({ theme }) => theme.fonts.primary};
  letter-spacing: 1px;
  
  ${({ $uppercase }) => $uppercase && css`
    text-transform: uppercase;
  `}

  ${({ $inline }) => $inline && css`
    display: inline-block;
  `}

  ${({ variant }) => variant && css`
    color: ${getColor(variant)};
  `}

  ${({ $noSelect }) => $noSelect && css`
    user-select: none;
  `}

  & > a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
  }
`;

