import * as React from 'react';

import { Block, Image } from 'common/layout';

import { Logo } from './styled';

export const ImageBlock: React.FC<ImageBlockProps> = ({ src, alt, fullHeight = true, logoInclude, ...props }) => {
  return (
    <Block>
      <Image
        src={src}
        alt={alt}
        fullHeight={fullHeight}
        {...props}
      />
      {logoInclude && (
        <Logo />
      )}
    </Block>
  );
};

type ImageBlockProps = {
  src: string;
  alt: string;
  fullHeight?: boolean;
  logoInclude?: boolean;
};
