import * as i from 'types';
import styled, { css } from 'styled-components';

import { getColor, media } from 'styles/utils';
import LogoSvg from 'vectors/logo-svg.svg';

export const LogoIcon = styled(LogoSvg)<LogoIconProps>`
  height: 24px;
  width: 118px;
  cursor: pointer;

  ${media.desktop`
    height: 52px;
    width: 262px;
  `}

  ${({ variant }) => variant && css`
    path {
      fill: ${getColor(variant)};
    }
  `};
`;

type LogoIconProps = {
  variant?: i.ThemeColors;
};
