import styled, { css } from 'styled-components';
import { media } from 'styles/utils';

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-content: center;
  align-items: center;
  padding: 24px;
  color: ${({ theme }) => theme.colors.white};

  ${media.desktop`
    padding: 48px 56px;
  `}

  ${({ $backgroundColor }) => $backgroundColor && css`
    background-color: ${(props) => props.theme.colors.primary};
  `}

  ${({ $fullHeight }) => $fullHeight && css`
    height: 100vh;
  `}

  ${({ $column }) => $column && css`
    flex-direction: column;
  `}

  ${({ $center }) => $center && css`
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;

type ContainerProps = {
  $center?: boolean;
  $column?: boolean;
  $backgroundColor?: boolean;
  $fullHeight?: boolean;
};
