import * as i from 'types';
import { createSelector } from 'reselect';

export const selectSiteData = (state: i.ReduxState) => state.sites.data.sites;
export const selectActiveSiteData = (state: i.ReduxState) => state.sites?.activeSite;

export const sitesCitiesSelector = createSelector(
  (state: i.ReduxState) => state.sites?.data?.sites,
  (sites) => {

    const cityOptions = sites.map((site) => {
      return {
        label: site.city,
        value: site.city,
      };
    });

    return cityOptions;
  },
);

export const selectSiteStudios = createSelector(
  selectSiteData,
  selectActiveSiteData,
  (sites, activeSite) => sites.find((site) => site.city === activeSite)?.studios,
);
