import styled, { css } from 'styled-components';
import { Swiper } from 'swiper/react';

import { Text } from 'common/typography';
import { media } from 'styles/utils';

export const ExtendedText = styled(Text)`
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  margin: 0 0 0 32px;

  ${media.desktop`
    margin: 0 0 0 96px;
  `}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  max-width: 15px;

  svg {
    width: 100%;
    height: 15px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

export const CarouselContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  gap: 4px;
`;

export const HorizontalLine = styled.div`
  border-radius: 4px;
  width: 100%;
  opacity: 0.5;
  border: 1px solid ${({ theme }) => theme.colors.gray.background};
`;

export const CarouselContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 16px 16px 16px;
  gap: 8px;
`;

export const CarouselImage = styled.div<CarouselImageProps>`
  height: 240px;
  background: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  width: 100%;
  display: flex;
`;

export const ImageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 0 13px 16px;
`;

type CarouselImageProps = {
  imageUrl: string;
};

export const ExtendedSwiper = styled(Swiper)`
  display: flex;
  flex-direction: column-reverse;
`;

export const CarouselSlide = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

export const CarouselContainer = styled.div`
  margin: 0 0 8px 32px;

  ${media.desktop`
    margin: 0 0 40px; 0;
  `}

  .carousel {
    width: 100%;
  }

  .carousel__slide {
    margin: 0;
    height: 254px;
    border-radius: 4px;
  }

  .carousel__placeholder {
    display: none;

    ${media.desktop`
      display: block;
      width: 85px!important;
    `}
  }
`;

export const SwiperContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const ClassesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 32px;
`;

export const ProgressbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 0 32px 24px 32px;
`;

export const Progressbar = styled.div<ProgressBarProps>`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray.medium};
  transition: .2s all;

  ${({ active }) => active && css`
    background-color: ${({ theme }) => theme.colors.gray.dark};
  `}
`;

type ProgressBarProps = {
  active?: boolean;
};
