import * as i from 'types';
import dayjs from 'dayjs';
import { createSelector } from 'reselect';
import { formatDateRaw } from 'services';
import { selectActiveBookings } from './booking';

export const classesListData = (state: i.ReduxState) => state.classes?.data;
export const selectActiveFilters = (state: i.ReduxState) => state.classes.activeFilters;
export const selectFilterOptions = (state: i.ReduxState) => state.classes.filterOptions;
export const selectFilteredClasses = (state: i.ReduxState) => state.classes.trainerList;

export const selectTrainerFilteredClasses = createSelector(
  selectFilteredClasses,
  (items) => {
    const formattedClasses: i.FormattedClassesType = {};

    items?.filter((item) => {
      const startTime = dayjs(item.classDate);
      return startTime.isAfter(new Date()) && !item.cancelled;
    })
      .slice(0, 4)
      .forEach((item) => {
        const formatDate = formatDateRaw(item.classDate);
        formattedClasses[formatDate] = formattedClasses[formatDate] ? [...formattedClasses[formatDate], item] : [item];
      });

    return formattedClasses;
  },
);

export const selectActiveFiltersCount = createSelector(
  selectActiveFilters,
  (activeFilters) => {
    let count = 0;

    activeFilters && Object.entries(activeFilters).forEach(([key, value]) => {
      if (key !== 'date' && value.length > 0) count += 1;
    });

    return count;
  },
);

export const selectClasses = (
  state: i.ReduxState, activeFilters: i.ActiveClassesFilterRecord,
) => createSelector(
  classesListData,
  selectActiveBookings,
  (classes, bookings) => {
    const activeDay = activeFilters?.date?.[0];

    let filteredClasses = classes?.filter((item) => {
      const startTime = dayjs(item.classDate);
      return startTime.isAfter(new Date()) && !item.cancelled;
    });

    if ((activeFilters?.dayparts?.length || 0) > 0) {
      const afternoon = dayjs(activeDay).set('hours', 11).set('minutes', 59);
      const evening = dayjs(activeDay).set('hours', 16).set('minutes', 59);

      if ((activeFilters?.instructors?.length || 0) > 0) {
        filteredClasses = filteredClasses?.filter((item) => (
          activeFilters?.instructors?.includes(item.instructorId)
        ));
      }

      filteredClasses = filteredClasses?.filter((item) => {
        let isActive = false;
        if (activeFilters?.dayparts?.includes('morning') && !isActive) {
          isActive = dayjs(item.classDate).isBefore(afternoon);
        }

        if (activeFilters?.dayparts?.includes('afternoon') && !isActive) {
          isActive = dayjs(item.classDate).isBefore(evening)
            && dayjs(item.classDate).isAfter(afternoon);
        }

        if (activeFilters?.dayparts?.includes('evening') && !isActive) {
          isActive = dayjs(item.classDate).isAfter(evening);
        }

        return isActive;
      });
    }

    if ((activeFilters?.studios?.length || 0) > 0) {
      filteredClasses = filteredClasses?.filter((item) => (
        activeFilters?.studios?.includes(item.siteId)
      ));
    }

    if ((activeFilters?.instructors?.length || 0) > 0) {
      filteredClasses = filteredClasses?.filter((item) => (
        activeFilters?.instructors?.includes(item.instructorId)
      ));
    }

    if ((activeFilters?.classTypes?.length || 0) > 0) {
      filteredClasses = filteredClasses?.filter((item) => (
        activeFilters?.classTypes?.includes(item.classTypeId)
          || (activeFilters?.classTypes?.includes('event') && item.event)
      ));
    }

    return filteredClasses?.map((item) => ({
      ...item,
      isBooked: bookings?.some((booking) => booking.classId === item.id),
      isWaitlisted: bookings?.some((booking) =>
        booking.classId === item.id && booking.status === 'Waitlisted'),
      isWaitlistExpired: bookings?.some((booking) =>
        booking.classId === item?.id && booking.status === 'Waitlist Expired'),

    }));
  },
)(state);
