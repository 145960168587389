import * as i from 'types';
import * as React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';

import { NavigationItem, NavigationSubTitle, SublistContainer, NavigationList } from './styled';

export const NavigationItemContainer:React.FC<NavigationItemContainerProps> = ({
  navigationItem, onClose,
}) => {
  const [openSubList, setOpenSubList] = React.useState(false);
  const hasBody = 'body' in navigationItem;

  const menuLabelAnimation = {
    visible: {
      opacity: 1,
      x: '0px',
    },
    hidden: {
      opacity: 0,
      x: '20px',
    },
  };

  const toggleMenu = () => setOpenSubList(!openSubList);
  const checkIfHome = (slug: string) => slug === '/' ? '' : slug;

  return (
    <>
      {!hasBody && (
        <NavigationItem variants={menuLabelAnimation} key={navigationItem?.id}>
          <Link to={`/${checkIfHome(navigationItem.slug)}` || ''} onClick={onClose}>
            {navigationItem?.title}
          </Link>
        </NavigationItem>
      )}

      {hasBody && (
        <NavigationItem variants={menuLabelAnimation} key={navigationItem?.id}>
          <NavigationSubTitle onClick={toggleMenu}>{navigationItem.listTitle}</NavigationSubTitle>
          <SublistContainer isOpen={openSubList}>
            <NavigationList variants={menuLabelAnimation} key={navigationItem?.id}>
              {navigationItem.body?.map((el) =>
                <Link key={el.id} to={`/${el.slug}` || ''}>
                  {el?.title}
                </Link>,
              )}
            </NavigationList>
          </SublistContainer>
        </NavigationItem>
      )}
    </>
  );
};

type NavigationItemContainerProps = {
  navigationItem: i.NavigationItemSingleSlug | i.NavigationItemWithBody;
  onClose: () => void;
};
