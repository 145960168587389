import styled from 'styled-components';

import { media } from 'styles/utils';

export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  height: 48px;
  width: 48px;
  border-radius: 24px;
  cursor: pointer;
  box-shadow: 2px 10px 112px -1px rgba(0,0,0,0.41);
  position: fixed;
  left: 24px;
  top: 24px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.desktop`
    left: 32px;
    top: 32px;
  `}
`;
