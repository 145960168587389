import styled, { css } from 'styled-components';
import { media } from 'styles/utils';

export const TextContainer = styled.div<TextContainerProps>`
  text-align: center;
  display: flex;
  flex-direction: column;

  ${({ $align }) => $align === 'left' && css`
    text-align: left;
  `}

  ${({ $align }) => $align === 'center' && css`
    text-align: center;
    align-items: center;
  `}

  ${({ $align }) => $align === 'right' && css`
    text-align: right;
    align-items: flex-end;
  `}

  p, ul, li {
    color: ${({ theme }) => theme.colors.gray.dark};
  }

  p {
    margin-bottom: 1em;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${media.desktop`
    max-width: 790px;
    margin: 0 auto;
  `}

  ${media.large`
    max-width: 1000px;
  `}
`;

type TextContainerProps = {
  $align?: 'left' | 'center' | 'right';
};
