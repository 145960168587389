import * as React from 'react';

import { Headline1, Heading } from 'common/typography';
import { Logo, Headline1Variant } from 'common/general';

import {
  HeaderContainer, LogoWrapper, Container, ChildWrapper, HeadingSpan,
} from './styled';

export const HalfHeader: React.FC<HalfHeaderProps> = ({
  title, subtitle, children, imageUrl, value, marginBottom,
}) => {
  return (
    <HeaderContainer {...{ imageUrl, marginBottom }}>
      <Container>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <div>
          <Heading as="h3" margin="24px 0 0" upperCase>{title}</Heading>
          <Headline1
            {...Headline1Variant}
            margin="8px 0"
          >
            {subtitle && subtitle} <br />
            {value && <HeadingSpan>{value}</HeadingSpan>}
          </Headline1>
        </div>
        <ChildWrapper>
          {children}
        </ChildWrapper>
      </Container>
    </HeaderContainer>
  );
};

type HalfHeaderProps = {
  title?: string;
  subtitle?: string;
  imageUrl: string;
  value?: string;
  marginBottom?: number;
};
