import * as React from 'react';

import { TagContainer, ExtendedText } from './styled';

export const Tag: React.FC<TagProps> = ({ item }) => (
  <TagContainer>
    <ExtendedText>{item}</ExtendedText>
  </TagContainer>
);

type TagProps = {
  item: string;
};
