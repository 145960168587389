import * as i from 'types';
import { createSelector } from 'reselect';

export const selectMeSeries = (state: i.ReduxState) => state.meSeries.data.series || [];
export const selectMeContracts = (state: i.ReduxState) => state.meSeries.data.contracts || [];
export const selectAmountContracts = (state: i.ReduxState) => state.meSeries.amountContracts;

export const selectActiveContract = (state: i.ReduxState) => {
  const contracts = state.meSeries.data.contracts || [];

  if (contracts.length === 0) {
    return undefined;
  }

  // Return the latest purhcased contract
  return contracts.sort((a, b) => new Date(b.purchaseDate).getTime() - new Date(a.purchaseDate).getTime())[0];
};

export const selectTotalRemainingSeries = createSelector(
  selectMeSeries,
  (series) => {
    const result = series.reduce((sum, serie) => {
      return sum = sum + serie.classesRemaining;
    }, 0);

    return result;
  },
);
