import * as React from 'react';

import { useDevice } from 'services/hooks';
import { Modal } from 'common/layout';
import { Button } from 'common/interaction';
import { Text } from 'common/typography';

import {
  Container, ExtendedHeading, ButtonContainer, ModalHeader, CloseIcon, ContentContainer,
} from './styled';

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen, closeModal, confirmAction, title, description,
}) => {
  const { isDesktop } = useDevice();

  const mobileVariant = {
    open: { x: 0 },
    closed: { x: '-100%' },
  };

  const desktopVariant = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
  };

  const animationVariant = isDesktop ? desktopVariant : mobileVariant;

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Container
        key="confirmModal"
        animate={isOpen ? 'open' : 'closed'}
        initial="open"
        variants={animationVariant}
      >
        <ModalHeader>
          <CloseIcon onClick={closeModal} />
        </ModalHeader>
        <ContentContainer>
          <ExtendedHeading as="h2">{title}</ExtendedHeading>
          {description && (
            <Text $lineHeight="20">{description}</Text>
          )}
          <ButtonContainer>
            <Button size="fullWidth" onClick={closeModal} variant="warning">Cancel</Button>
            <Button size="fullWidth" onClick={confirmAction} variant="CTA">Confirm</Button>
          </ButtonContainer>
        </ContentContainer>
      </Container>
    </Modal>
  );
};

type ConfirmModalProps = {
  isOpen: boolean;
  confirmAction: () => void;
  title: string;
  description?: string;
  closeModal: () => void;
};
