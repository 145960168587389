import React from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';

import { useAuthenticationUser } from 'services/hooks';

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, location, ...rest }) => {
  const { determined, authenticated, loading } = useAuthenticationUser();

  if (!determined) return null;

  return loading ? (
    <div>loading...</div>
  ) : authenticated ? (
    <Component {...rest} />
  ) : (
    <Redirect noThrow to={'/login'} />
  );
};

type PrivateRouteProps = RouteComponentProps & {
  component: React.ElementType;
};
