import React from 'react';
import ReactDOM from 'react-dom';
import { AnimatePresence } from 'framer-motion';

import { isBrowser, lockBodyScroll } from 'services';

import { Container, StyledModal, Overlay } from './styled';

const modalRoot: HTMLElement | null = isBrowser() ? document.getElementById('modal')! : null;

const ModalContent: React.FC<ModalProps> = ({ children, isOpen, isCenter, onClose }) => {
  const overlayRef = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    lockBodyScroll(isOpen);

    return () => {
      lockBodyScroll(false);
    };
  }, [isOpen]);

  if (!modalRoot) return null;

  return (
    ReactDOM.createPortal((
      <Container
        key="layoutModal"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        isCenter={isCenter}
      >
        <StyledModal>
          {children}
        </StyledModal>
        <Overlay ref={overlayRef} onClick={() => onClose()} />
      </Container>
    ), modalRoot)
  );
};

export const Modal: React.FC<ModalProps> = ({ children, isOpen, isCenter, onClose }) => {
  return (
    // AnimatePresence allows components to animate out when they're removed from the React tree.
    <AnimatePresence>
      {isOpen && (
        <ModalContent isOpen={isOpen} onClose={onClose} isCenter={isCenter} key="modalContent">
          {children}
        </ModalContent>
      )}
    </AnimatePresence>
  );
};

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isCenter?: boolean;
};
