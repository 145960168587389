import * as i from 'types';
import { createSelector } from 'reselect';
import dayjs from 'dayjs';

import { isSameOrAfterToday } from 'services';

export const selectBookings = (state: i.ReduxState) => state.booking.data;

export const selectActiveBookings = (state: i.ReduxState) =>
  state.booking.data?.filter((booking) =>
    booking.status === 'Enrolled' || booking.status === 'Waitlisted');

export const selectActiveBooking = (state: i.ReduxState, classId: string) =>
  state.booking.data?.find((booking) =>
    booking.status !== 'Cancelled' && booking.status !== 'No Show' && classId === booking.classId);

export const selectUpcomingBookings = createSelector(
  selectActiveBookings,
  (bookings) => bookings?.filter((booking) => isSameOrAfterToday(booking.classDate)),
);

export const selectRewardElligibleBookings = createSelector(
  selectActiveBookings,
  (bookings) => bookings?.filter((booking) =>
    dayjs(booking.classDate).isAfter(dayjs('2010-01-01')) && dayjs(booking.classDate).isBefore(dayjs())).length || 0,
);
