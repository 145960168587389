import * as React from 'react';
import Select, { components, ValueContainerProps } from 'react-select';

import { getSites, sitesActions } from 'ducks/sites';
import { getLocalData, setLocalData } from 'services';
import { useAuthenticationUser, useSelector, useDispatch } from 'services/hooks';
import { sitesCitiesSelector, selectActiveSiteData } from 'selectors/sites';
import { LocationMarker } from 'common/svg';

import customStyles from './customStyles';
import { Span, TextContainer, ClassesOfText } from './styled';

export const SiteSelector:React.FC<SiteSelectorProps> = ({ handleSelect, label }) => {
  const dispatch = useDispatch();
  const cities = useSelector(sitesCitiesSelector);
  const isSelectDisabled = Boolean(cities.length === 0);
  const activeSite = useSelector(selectActiveSiteData);
  const localSite = getLocalData('user::activeSite');
  const { determined } = useAuthenticationUser();

  React.useEffect(() => {
    if (localSite !== activeSite && determined) {
      dispatch(getSites())
        .then((sites) => {
          handleSiteChange(localSite || sites[0]?.city);
        });
    }
  }, [determined]);

  const handleSiteChange = (value: string) => {
    setLocalData('user::activeSite', value);
    dispatch(sitesActions.setActiveSite(value));
    if (handleSelect) handleSelect();
  };

  const ValueContainer = ({
    children,
    ...props
  }: ValueContainerProps<CityOption>) => (
    <components.ValueContainer {...props}>
      <Span>
        <LocationMarker />
        <TextContainer>
          <ClassesOfText>{label}</ClassesOfText>
          {children}
        </TextContainer>
      </Span>
    </components.ValueContainer>
  );

  return (
    <>
      {activeSite && (
        <Select
          defaultValue={{
            label: activeSite,
            value: activeSite,
          }}
          styles={customStyles}
          options={cities}
          isDisabled={isSelectDisabled}
          isSearchable={false}
          isClearable={false}
          hideSelectedOptions={true}
          components={{ ValueContainer }}
          onChange={(event) => handleSiteChange(event?.value || '')}
        />
      )}
    </>
  );
};

type SiteSelectorProps = {
  site?: string;
  label?: string;
  handleSelect?: () => void;
}

type CityOption = {
  label: string;
  value: string;
  children: React.ReactNode;
};
